define("discourse/plugins/discourse-pm-scanner/discourse/initializers/extend-for-pm-scanner", ["exports", "discourse-common/lib/icon-library"], function (_exports, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-for-pm-scanner",
    initialize() {
      (0, _iconLibrary.replaceIcon)("notification.pm_scanner.notification.found", "exclamation");
    }
  };
});